import React from "react";
import colorSharp from "../assets/img/color-sharp.png";
import "@fortawesome/fontawesome-free/css/all.min.css";

export const Idea = () => {


    return (





        <section className="idea" id="idea">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="idea-bx wow zoomIn">
                            <h2>Idee</h2>
                            <p>Willkommen zu meinem Projekt, bei dem ich mittels künstlicher Intelligenz einzigartige Kunstwerke und Fotografien erschaffe. Ich nutze die Technologie, um Fotos zu generieren oder zu verändern und verfeinere diese anschliessend durch eine manuelle Bearbeitung.</p><p> Mein Ziel ist es, Kunstwerke zu schaffen, die einen Bezug zur Realität haben und bei denen Personen oder Orte noch erkennbar sind. Durch die Kombination aus Technologie und manueller Kunstfertigkeit hoffe ich, neue Perspektiven auf bekannte Themen zu schaffen und das Potenzial der künstlichen Intelligenz in der Kunst auszuloten.</p><p> Ich bin gespannt, welche unglaublichen Werke wir gemeinsam erschaffen werden.</p>


                        </div>
                    </div>
                </div>
            </div>
            <img className="background-image-left" src={colorSharp} alt="Image" />
        </section>
    );
}
