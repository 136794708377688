import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { PortfolioItemCard } from "./PortfolioItemCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import Masonry from '@mui/lab/Masonry';

import portImg1 from "../assets/img/portfolio/generated_blackdress_1.png";
import portImg2 from "../assets/img/portfolio/generated_blackdress_2.png";
import portImg3 from "../assets/img/portfolio/generated_blackdress_3.png";
import portImg4 from "../assets/img/portfolio/generated_skiingportrait_1.png";
import portImg5 from "../assets/img/portfolio/photo_jana_1.png";
import portImg6 from "../assets/img/portfolio/woman_illuminateddress1.png";

export const Portfolio = () => {

  const portfolioitems = [
    {
      title: "Black & Elegant",
      description: "Woman in black dress",
      imgUrl: portImg1,
    },
    {
      title: "Black & Elegant",
      description: "Woman in black dress",
      imgUrl: portImg2,
    },
    {
      title: "Black & Elegant",
      description: "Woman in black dress",
      imgUrl: portImg3,
    },
    {
      title: "Skiing",
      description: "Up a mountain",
      imgUrl: portImg4,
    },
    {
      title: "Real Photo",
      description: "Newly arranged",
      imgUrl: portImg5,
    },
    {
      title: "Illuminated Dress",
      description: "New material design",
      imgUrl: portImg6,
    },
  ];

  return (
    
    <section className="project" id="portfolio">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Portfolio</h2>
                <p>Das Portfolio wird stetig erweitert.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Fotografiert</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Generiert</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Aufpoliert</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Masonry columns={{ xs: 1, sm: 2 }} spacing={2}>
                        {
                          portfolioitems.map((portfolioitem, index) => {
                            return (
                              <PortfolioItemCard
                                key={index}
                                {...portfolioitem}
                                />
                            )
                          })
                        }
                      </Masonry>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="background"></img>
    </section>
  )
}
