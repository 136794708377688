import React from "react";
import colorSharp from "../assets/img/color-sharp.png";
import { Carousel, Image, Container, Row, Col } from 'react-bootstrap';
import "@fortawesome/fontawesome-free/css/all.min.css";

import testmonial1 from "../assets/img/testmonials/testmonial_thomas.png";
import testmonial2 from "../assets/img/testmonials/jana.png";

export const Testmonials = () => {

    const testimonial = [
        {
            content:
                "Ich habe noch nie solch realistische Kunstwerke gesehen. Die Kombination aus Technologie und manueller Nachbearbeitung ist einfach unglaublich.",
            author: "Thomas M.",
            image: testmonial1
        },
        {
            content:
                "I cannot tell you how much we loved using this silent auction software. Everything was seamless…from set up, to bidding, to payment. We will absolutely use MyEvent next year.",
            author: "Sarah M., CCHS Foundation",
            image: testmonial2
        },
        {
            content:
                "I tried MyEvent instead of typical paper raffle tickets. The system was easy to set up online and people who couldn't attend the event were still able to enter the raffle, which was HUGE bump in revenue.",
            author: "Alexander B., Pan-Mass Challenge",
            image: testmonial1
        },
        {
            content:
                "MyEvent is a great way to bring in money for your Fund A Need. The 24/7 tech support allows you to feel confident, and the platform makes your Fund a Need so much easier to run. Well definitely be using MyEvent again.",
            author: "Amy C., One Less Orphan Fund",
            image: testmonial2
        }
    ];


    return (





        <section className="testmonial" id="testmonials">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="testmonial-bx wow zoomIn">
                            <h2>Kunden- & Partnerfeedback</h2>
                            <p>Wir sind stolz darauf, was unsere Kunden über unsere Produkte und Dienstleistungen zu sagen haben. <br></br> Unsere Kundenfeedbacks sind ein wichtiger Bestandteil unserer Arbeit und geben uns wertvolles Feedback.</p>




                            <Carousel fade>
                                {testimonial.map((c, index) => {
                                    return (
                                        <Carousel.Item>

                                            <Container>
                                                <Row className="justify-content-md-center">
                                                    <Col sm={2}></Col>
                                                    <Col sm={2}><Image src={c.image} roundedCircle></Image> </Col>
                                                    <Col sm>"{c.content}" - {c.author}</Col>
                                                    <Col sm={2}></Col>
                                                </Row>
                                            </Container>




                                        </Carousel.Item>
                                    );
                                })}

                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <img className="background-image-left" src={colorSharp} alt="Image" />
        </section>
    );
}
